import React, { useState, useEffect, useRef } from 'react';
// import css from './CustomTopbarWrapper.module.css';

const CustomTopbarWrapper = (props) => {
    const { children } = props;

    const ref = useRef(null);
    const navBarHeight = ref?.current?.clientHeight || 0;

    const [isScrollingUp, setIsScrollingUp] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);

    const scrollAfterHeader = lastScrollY >= navBarHeight;

    useEffect(() => {
        if (typeof window !== "undefined" && window.innerWidth > 768) {
            const handleScroll = () => {
                const currentScrollY = window.scrollY;

                if (currentScrollY > lastScrollY) {
                    setIsScrollingUp(false);
                    // if (currentScrollY < navBarHeight) {
                    //     window.scrollTo(0, navBarHeight);
                    // }
                } else {
                    setIsScrollingUp(true);
                };

                setLastScrollY(currentScrollY);
            };

            window.addEventListener("scroll", handleScroll);

            return () => {
                window.removeEventListener("scroll", handleScroll);
            };
        };
    }, [lastScrollY]);

    return (
        <div
            // className={css.root}
            ref={ref}
            style={{
                position: scrollAfterHeader || isScrollingUp ? 'sticky' : 'unset',
                top: isScrollingUp ? 0 : `-${navBarHeight}px`,
                transition: scrollAfterHeader ? 'top 0.4s ease-in-out 0.2s' : '',
                zIndex: 10,
            }}
        >
            {children}
        </div>
    )
};

export default CustomTopbarWrapper;
